/* Layouts */
const Layout = () => import("@/layout/index.vue");
/* Layouts */

/* Authenticated Component */
const Dashboard = () => import("@/views/Dashboard.vue");
/* Authenticated Component */

export default [
    {
        path: "/",
        component: Layout,
        redirect: "/dashboard",
        // alwaysShow: true, // will always show the root menu
        children: [
            {
                name: "dashboard",
                path: "dashboard",
                component: Dashboard,
                meta: {
                    title: "Dashboard",
                    elSvgIcon: "Fold",
                    affix: true,
                    // roles: ["super-admin", "normal-user"],
                },
            },
        ],
    },
];
