//获取用户信息
import axiosReq from "@/utils/axios-req";
export const getAllCompanies = () => {
    return new Promise((resolve) => {
        const reqConfig = {
            url: "/api/v1/companies/index",
            method: "get",
        };
        axiosReq(reqConfig).then(({ data }) => {
            resolve(data);
        });
    });
};

export const getMyCompany = () => {
    return new Promise((resolve) => {
        const reqConfig = {
            url: "/api/v1/companies/show",
            method: "get",
        };
        axiosReq(reqConfig).then(({ data }) => {
            resolve(data);
        });
    });
};
