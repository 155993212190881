/* Layouts */
const Layout = () => import("@/layout/index.vue");
/* Layouts */

/* Authenticated Component */
const Permissions = () => import("@/views/permissions/index.vue");
const Roles = () => import("@/views/roles/index.vue");
const Users = () => import("@/views/users/index.vue");
/* Authenticated Component */

export default [
    {
        path: "/system",
        component: Layout,
        meta: { title: "System", icon: "tree", roles: ["super-admin"] },
        // alwaysShow: true,
        children: [
            {
                name: "users",
                path: "users",
                component: Users,
                meta: { title: "Users" },
            },
            {
                name: "roles",
                path: "roles",
                component: Roles,
                meta: { title: "Roles" },
            },
            {
                name: "permissions",
                path: "permissions",
                component: Permissions,
                meta: { title: "Permissions" },
            },
        ],
    },
];
